// IMPORTS
import React from "react";
import JoinWhiteImage from "../assets/images/text/join-white.png";
import JoinBlueImage from "../assets/images/text/join-blue.png";
import OtherGuildImage from "../assets/images/text/title.svg";
import { links } from "../helpers/variables";
// COMPONENT
const MembersOnly = ({ props }) => {
  // STATE
  const { state, refs } = props;
  const { isMobile } = state;
  // RENDER
  return (
    <div ref={refs.membersOnlyRef} className="membersOnlyContainer">
      {/* BACKGROUND IMAGES */}
      <div className="membersOnlyBackgroundImagesContainer">
        {Array(3)
          .fill("")
          .map((_, index) => {
            return (
              <div
                key={index}
                className="membersOnlyBackgroundImage"
                style={{
                  backgroundImage: `url(${
                    index === 1 ? JoinWhiteImage : JoinBlueImage
                  })`,
                  backgroundPositionX: `calc(${index} * ${
                    isMobile ? "35rem" : "60rem"
                  })`,
                }}
              />
            );
          })}
      </div>
      {/* JOIN BUTTON */}
      <a
        className="membersOnlyJoinButton"
        href={links.discord}
        target="_blank"
        rel="noreferrer"
      >
        Join the OtherGuild
      </a>
      {/* TITLE IMAGE */}
      <img
        className="membersOnlyTitleImage"
        alt="OtherGuild"
        src={OtherGuildImage}
      />
    </div>
  );
};
// EXPORT
export default MembersOnly;
