// IMPORTS
import Obelisk1 from "../assets/images/obelisk/obelisk-1.png";
import Obelisk2 from "../assets/images/obelisk/obelisk-2.png";
import Obelisk3 from "../assets/images/obelisk/obelisk-3.png";
import Obelisk4 from "../assets/images/obelisk/obelisk-4.png";
import Obelisk5 from "../assets/images/obelisk/obelisk-5.png";
import Obelisk6 from "../assets/images/obelisk/obelisk-6.png";
import Obelisk7 from "../assets/images/obelisk/obelisk-7.png";
import KisherAvatar from "../assets/images/avatars/kisher.png";
import AirwalkAvatar from "../assets/images/avatars/airwalk.png";
import FalconzAvatar from "../assets/images/avatars/falconz.png";
import G2Avatar from "../assets/images/avatars/g2.jpeg";
import AxolotlAvatar from "../assets/images/avatars/axolotl.jpeg"
import RothAvatar from "../assets/images/avatars/roth.png"
import SkryptoAvatar from "../assets/images/avatars/skrypto.jpeg";
import IconSeedAvatar from "../assets/images/avatars/icon-seed.png";
import Deed1 from "../assets/images/deeds/deed-1.jpeg";
import Deed2 from "../assets/images/deeds/deed-2.jpeg";
import Deed3 from "../assets/images/deeds/deed-3.jpeg";
import Deed4 from "../assets/images/deeds/deed-4.jpeg";
import Deed5 from "../assets/images/deeds/deed-5.jpeg";
import Deed6 from "../assets/images/deeds/deed-6.jpeg";
import Deed7 from "../assets/images/deeds/deed-7.jpeg";
// ORBS
const orbs = [
  {
    key: "We Prepare",
    value:
      "We create the OtherGuild workshop, building the best possible tools to simplify the mysteries of the Otherside. We prepare for the voyage, ready to step into the Otherside!",
    image: Obelisk1,
    x: { mobile: "calc(50% - 2rem)", desktop: "calc(50% - 3rem)" },
    y: { mobile: "calc(var(--vh) * 71)", desktop: "20%" },
  },
  {
    key: "We Explore",
    value:
      "The OtherGuild ventures from the Guild Hall to explore every facet of the Otherside. We learn all we can in order to prepare for the great voyage.",
    image: Obelisk2,
    x: { mobile: "25vw", desktop: "31.5%" },
    y: { mobile: "calc(var(--vh) * 59.5)", desktop: "36%" },
  },
  {
    key: "We Organize",
    value:
      "We evolve and adapt as a community. Together we forge new paths and opportunities, always doing our best to guide each other on the voyage. With the aid of our guild tools, we grow, fortify, and maintain our network.",
    image: Obelisk3,
    x: { mobile: "65vw", desktop: "61%" },
    y: { mobile: "calc(var(--vh) * 59.5)", desktop: "36%" },
  },
  {
    key: "We Build",
    value:
      "The OtherGuild adapts its focus and structure as the Otherside changes and expands. We continue to create unique tools, advantages, and opportunities for our fellow members and allies. Together we optimize the OtherGuild into an unstoppable network!",
    image: Obelisk4,
    x: { mobile: "calc(50% - 2rem)", desktop: "calc(50% - 3rem)" },
    y: { mobile: "calc(var(--vh) * 45)", desktop: "52%" },
  },
  {
    key: "We Prosper",
    value:
      "There is a rumor of a great market and arcade filled with wonders and delights never before seen. We venture together in search of legends, fortune, and glory!",
    image: Obelisk5,
    x: { mobile: "calc(50% - 2rem)", desktop: "calc(50% - 3rem)" },
    y: { mobile: "calc(var(--vh) * 30)", desktop: "68%" },
  },
  {
    key: "We Rise Above",
    value:
      "We remain neutral in all conflicts outside the business of OtherGuild. We support our guild members and allies, but we will not take sides. We maintain the highest standards of integrity. The business of the OtherGuild is business!",
    image: Obelisk6,
    x: { mobile: "calc(50% - 2rem)", desktop: "calc(50% - 3rem)" },
    y: { mobile: "calc(var(--vh) * 20)", desktop: "80%" },
  },
  {
    key: "We Expand",
    value:
      "The OtherGuild promotes members from within the community. We always look to strengthen the OtherGuild, the Workshop, the Koda Chemistry Lab, and our OtherGuilders.",
    image: Obelisk7,
    x: { mobile: "calc(50% - 2rem)", desktop: "calc(50% - 3rem)" },
    y: { mobile: "calc(var(--vh) * 8)", desktop: "95%" },
  },
];
// TEAM
const team = [
  {
    name: "Kisher",
    description: "Project Lead & Creative Director",
    image: KisherAvatar,
    handle: "@kisher_eth",
    link: "https://twitter.com/kisher_eth",
  },
  {
    name: "Airwalk",
    description: "Strategic CTO",
    image: AirwalkAvatar,
    handle: "@AirWalk_NFT",
    link: "https://twitter.com/AirWalk_NFT",
  },
  {
    name: "Falconz",
    description: "Senior Developer",
    image: FalconzAvatar,
    handle: "@falconz_eth",
    link: "https://twitter.com/falconz_eth",
  },
  {
    name: "G2",
    description: "Tech Team Lead",
    image: G2Avatar,
    handle: "@maymax777",
    link: "https://twitter.com/maymax777",
  },
  {
    name: "Skrrypto",
    description: "Community / Social Management",
    image: SkryptoAvatar,
    handle: "@anon_fungible",
    link: "https://twitter.com/anon_fungible",
  },
  {
    name: "Icon Seed",
    description: "Producer & Content Lead",
    image: IconSeedAvatar,
    handle: "@Koda6246",
    link: "https://twitter.com/koda6246",
  },
  {
    name: "Axolotl",
    description: "Content & Partnerships",
    image: AxolotlAvatar,
    handle: "@Inside_Tokens",
    link: "https://twitter.com/Inside_Tokens",
  },
  {
    name: "Roth",
    description: "CFO",
    image: RothAvatar,
    handle: "@Roth",
    link: "https://www.linkedin.com/in/rothherrlinger/",
  },
];
// QUESTIONS
const questions = [
  {
    question: "How do I join OtherGuild ?",
    answer:
      "You can become a member of OtherGuild by joining our Discord at discord.gg/OtherGuild and filling out the Prospective Member form.",
  },
  {
    question: "What does it cost to join ?",
    answer:
      "There is no cost to join Otherguild. However, we do request that you engage and add value to the community and membership base by contributing your time and excitement.",
  },
  {
    question: "Do I need to connect my wallet ?",
    answer:
      "Currently, you do not need to connect your wallet to participate in the OtherGuild Discord. However, our website is token-gated, and you will need to submit a wallet to access the Member Dashboard, tools, and more. At this time, this does NOT need to be a wallet containing your Otherdeeds.",
  },
  { question: "Are you officially partnered with Otherside ?", answer: "No" },
  {
    question: "How can we contact OtherGuild ?",
    answer: "You can contact OtherGuild via ________",
  },
];
// DEEDS
const deeds = [
  Deed1,
  Deed2,
  Deed3,
  Deed4,
  Deed5,
  Deed6,
  Deed7,
  Deed1,
  Deed2,
  Deed3,
  Deed4,
  Deed5,
  Deed6,
  Deed7,
];
// LINKS
const links = {
  map: "https://map.otherguild.xyz",
  twitter: "https://twitter.com/theOtherGuild",
  discord: "https://discord.gg/DcjFBxWjh4",
};
// EXPORTS
export { orbs, team, questions, deeds, links };
