// IMPORTS
import React, { useEffect, useState } from "react";
import KodaVideo from "../assets/videos/koda.mp4";
import Video from "../components/Video";
// COMPONENT
const Roadmap = ({ props }) => {
  // STATE
  const { state, refs } = props;
  const { showObelisk, selectedOrb, isMobile, isMobileLandscape } = state;
  const { appRef, roadmapRef } = refs;
  const moveBehind = showObelisk || selectedOrb;
  const [playing, setPlaying] = useState(false);
  const playVideo =
    !isMobile &&
    !isMobileLandscape &&
    appRef.current?.scrollTop >= roadmapRef.current?.offsetTop - 200 &&
    appRef.current?.scrollTop <=
      roadmapRef.current?.offsetTop + roadmapRef.current?.offsetHeight - 100;
  // LIFE CYCLE
  useEffect(() => {
    setPlaying(playVideo);
  }, [playVideo]);
  // RENDER
  return (
    <div
      ref={roadmapRef}
      className="roadmapContainer"
      style={{ zIndex: moveBehind && "5" }}
    >
      {/* CONTENT */}
      <div className="roadmapContentContainer">
        {/* TITLE */}
        <p className="roadMapTitle">Our Vision</p>
        {/* TEXT LARGE */}
        <p className="roadmapTextLarge">Our Roadmap</p>
        {/* TEXT SMALL */}
        <p className="roadmapTextSmall">
          OtherGuild's mission is to maximize gameplay and social value for
          everyone in the Otherside through content, tools, and experiences -
          both digital and In Real Life (IRL).
        </p>
        {/* INSTRUCTIONS */}
        <p className="roadmapInstructions">Coming Soon</p>
        {/* VIDEO */}
        <div className="roadmapVideo">
          <Video
            props={{
              source: KodaVideo,
              playing: isMobile || isMobileLandscape ? "override" : playing,
            }}
          />
        </div>
      </div>
    </div>
  );
};
// EXPORT
export default Roadmap;
