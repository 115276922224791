// IMPORTS
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from "react-icons/fa";
// COMPONENT
const Video = ({ props }) => {
  // STATE
  const { source, playing } = props;
  const [muted, setMuted] = useState(true);
  const [playOverride, setPlayOverride] = useState(false);
  const override = playing === "override";
  // RENDER
  return (
    <div className="videoContainer">
      {/* PLAYER */}
      <ReactPlayer
        height="100%"
        width="100%"
        loop={true}
        url={source}
        playing={override ? playOverride : playing}
        muted={muted}
      />
      {/* PLAY BUTTON */}
      <button
        className="videoPlayButton"
        style={{ display: !override && "none" }}
        onClick={() => setPlayOverride(!playOverride)}
      >
        {playOverride ? <FaPause /> : <FaPlay />}
      </button>
      {/* MUTE BUTTON */}
      <button className="videoMuteButton" onClick={() => setMuted(!muted)}>
        {muted ? <FaVolumeUp /> : <FaVolumeMute />}
      </button>
    </div>
  );
};
// EXPORT
export default Video;
