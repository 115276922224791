// IMPORTS
import React, { Fragment } from "react";
import { CgMouse } from "react-icons/cg";
import { FaTwitter, FaDiscord } from "react-icons/fa";
import { links } from "../helpers/variables";
// COMPONENT
const Footer = ({ props }) => {
  // STATE
  const { state, refs } = props;
  const { scroll } = state;
  const { appRef } = refs;
  const showInstructions =
    scroll <=
    (appRef.current?.scrollHeight - appRef.current?.offsetHeight - 500 || 1);
  // RENDER
  return (
    <div className="footerContainer">
      {/* INSTRUCTIONS */}
      <div className="footerInstructionsContainer">
        {showInstructions && (
          <Fragment>
            <p className="footerInstructionsText">Scroll</p>
            <CgMouse className="footerInstructionsIcon" />
            <p className="footerInstructionsText">Down</p>
          </Fragment>
        )}
      </div>
      {/* SOCIAL MEDIA BUTTONS */}
      <div className="footerSocialMediaContainer">
        <a
          className="footerSocialMediaButton"
          href={links.twitter}
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter className="footerSocialMediaIcon" />
        </a>
        <a
          className="footerSocialMediaButton"
          href={links.discord}
          target="_blank"
          rel="noreferrer"
        >
          <FaDiscord className="footerSocialMediaIcon" />
        </a>
      </div>
    </div>
  );
};
// EXPORT
export default Footer;
