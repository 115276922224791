// EMIT ERROR
const emitError = (message, error) => {
  console.log(`${message} ERROR :: `, error);
};
// GET DATA
const getData = async (callback) => {
  const uri = "https://api.otherguild.xyz/reports/counts";
  const request = new Request(uri, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    }),
  });
  return await fetch(request)
    .then((response) => response.json())
    .then((response) =>
      callback({
        deeds: response.deedCount,
        kodas: response.kodaCount,
        artifacrts: response.artifactCount,
        members: response.memberCount,
      })
    )
    .catch((error) => emitError("FETCH API ERROR", error));
};
// TO PIXELS
const toPixels = (value) => {
  switch (true) {
    case value.includes("px"): {
      return value.slice(0, -2);
    }
    case value.includes("rem"): {
      return value.slice(0, -3) + "0";
    }
    default:
      return value;
  }
};
// EXPORTS
export { emitError, getData, toPixels };
