// IMPORTS
import React from "react";
import Obelisk from "../components/Obelisk";
// COMPONENT
const TheCharter = ({ props }) => {
  // STATE
  const { state, setState, refs } = props;
  const { showObelisk, selectedOrb, isMobile, isMobileLandscape } = state;
  const isStatic = showObelisk || selectedOrb;
  // RENDER
  return (
    <div className="theCharterContainer" ref={refs.theCharterRef}>
      {/* CONTENT */}
      <div className="theCharterContentContainer">
        {/* TITLE */}
        <p className="theCharterTitle">The Acropolis</p>
        {/* TEXT LARGE */}
        <p
          className="theCharterTextLarge"
          style={{ position: isStatic && "static" }}
        >
          Your search for answers <br /> has brought you here
        </p>
        {/* TEXT SMALL */}
        <p
          className="theCharterTextSmall"
          style={{ position: isStatic && "static" }}
        >
          As you approach a glowing doorway, an enigmatic but oddly comforting
          set of carvings appear on the Acropolis. As you look closer, an
          ancient guild charter becomes visible.
        </p>
        {/* INSTRUCTIONS */}
        <button
          className="theCharterInstructions"
          style={{ position: isStatic && "static" }}
          onClick={() => isMobile && setState({ ...state, showObelisk: true })}
        >
          {isMobile
            ? "Click Here to see the Obelisk"
            : isMobileLandscape
            ? "Rotate your phone to see the Obelisk"
            : "Click an Orb on the Acropolis to view our Charter"}
        </button>
        {/* OBELISK */}
        <div style={{ display: !showObelisk && "none" }}>
          <Obelisk
            props={{
              ...props,
              close: () => setState({ ...state, showObelisk: false }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
// EXPORT
export default TheCharter;
