// IMPORTS
import "./styles/App.scss";
import styles from "./styles/modules/_exports.module.scss";
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Loading from "./components/Loading";
import Obelisk from "./components/Obelisk";
import FAQ from "./pages/FAQ";
import Landing from "./pages/Landing";
import MembersOnly from "./pages/MembersOnly";
import OurStory from "./pages/OurStory";
import TheCharter from "./pages/TheCharter";
import Roadmap from "./pages/Roadmap";
import Team from "./pages/Team";
import { getData, toPixels } from "./helpers/hooks";
const mobileBreakpoint = toPixels(styles.mobileBreakpoint);
const tabletBreakpoint = toPixels(styles.tabletBreakpoint);
// COMPONENT
const App = () => {
  // STATE
  const [state, setState] = useState({
    page: "Landing",
    scroll: 0,
    showLoading: true,
    showObelisk: false,
    selectedOrb: null,
    selectedFAQ: null,
    data: {
      deeds: 702,
      kodas: 132,
      artifacrts: 173,
      members: 206,
    },
    isMobile: window.innerWidth <= mobileBreakpoint,
    isMobileLandscape: window.innerHeight <= mobileBreakpoint,
    isTablet: window.innerWidth <= tabletBreakpoint,
  });
  const { scroll, showObelisk, selectedOrb, isMobile, isMobileLandscape } =
    state;
  const [styleVariables, setStyleVariables] = useState({
    "--vh": `${window.innerHeight * 0.01}px`,
    "--vw": `${window.innerWidth * 0.01}px`,
  });
  const refs = {
    appRef: useRef(null),
    landingRef: useRef(null),
    ourStoryRef: useRef(null),
    theCharterRef: useRef(null),
    roadmapRef: useRef(null),
    teamRef: useRef(null),
    faqRef: useRef(null),
    membersOnlyRef: useRef(null),
  };
  const { appRef } = refs;
  const props = { state, setState, styles, refs };
  // LIFE CYCLE
  useEffect(() => {
    getData((data) => setState((_state) => ({ ..._state, data })));
    const handleOrientation = window.addEventListener(
      "orientationchange",
      () => {
        let isMobile = false;
        let isMobileLandscape = false;
        let isTablet = false;
        if (
          window.innerWidth <= mobileBreakpoint ||
          window.innerHeight <= mobileBreakpoint
        ) {
          window.innerHeight > window.innerWidth
            ? (isMobile = true)
            : (isMobileLandscape = true);
        } else if (window.innerWidth <= tabletBreakpoint) {
          isTablet = true;
        }
        setState((_state) => {
          return {
            ..._state,
            isMobile,
            isMobileLandscape,
            isTablet,
            selectedOrb: null,
            showObelisk: false,
          };
        });
        setStyleVariables({
          "--vh": `${window.innerHeight * 0.01}px`,
          "--vw": `${window.innerWidth * 0.01}px`,
        });
      }
    );

    const handleResize = window.addEventListener("resize", () => {
      let isMobile = false;
      let isMobileLandscape = false;
      let isTablet = false;
      if (
        window.innerWidth <= mobileBreakpoint ||
        window.innerHeight <= mobileBreakpoint
      ) {
        window.innerHeight > window.innerWidth
          ? (isMobile = true)
          : (isMobileLandscape = true);
      } else if (window.innerWidth <= tabletBreakpoint) {
        isTablet = true;
      }
      setState((_state) => {
        return { ..._state, isMobile, isMobileLandscape, isTablet };
      });
      setStyleVariables({
        "--vh": `${window.innerHeight * 0.01}px`,
        "--vw": `${window.innerWidth * 0.01}px`,
      });
    });
    return () => {
      window.removeEventListener(handleOrientation);
      window.removeEventListener(handleResize);
    };
  }, []);

  // RENDER
  return (
    <div
      ref={appRef}
      className="appContainer"
      style={{
        ...styleVariables,
        overflowY: (selectedOrb || showObelisk) && "hidden",
      }}
      onScroll={() => setState({ ...state, scroll: appRef.current?.scrollTop })}
    >
      {/* SCROLL BAR */}
      <div
        className="appScrollBar"
        style={{
          top: isMobile || isMobileLandscape ? scroll / 6 : scroll / 8.3,
          zIndex: selectedOrb && 5,
        }}
      />
      {/* COMPONENTS */}
      <Loading props={props} />
      <Header props={props} />
      {!isMobile && !isMobileLandscape && <Obelisk props={props} />}
      <Landing props={props} />
      <OurStory props={props} />
      <TheCharter props={props} />
      <Roadmap props={props} />
      <Team props={props} />
      <FAQ props={props} />
      <MembersOnly props={props} />
      <Footer props={props} />
    </div>
  );
};
// INITIALIZE
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
