// IMPORTS
import React, { Fragment } from "react";
import OtherGuildTitle from "../assets/images/text/title.svg";
import ObeliskImage from "../assets/images/obelisk/obelisk.png";
import { toPixels } from "../helpers/hooks";
// COMPONENT
const Loading = ({ props }) => {
  // STATE
  const { state, styles, refs } = props;
  const { scroll, showLoading, isMobile, isMobileLandscape } = state;
  const hideLoading = !showLoading || isMobile || isMobileLandscape;
  const headerHeight = toPixels(styles.headerHeight);
  const cutOff = refs.landingRef.current?.offsetTop - headerHeight - 1 || 1;
  // RENDER
  return (
    <Fragment>
      <div
        className="loadingContainer"
        style={{ display: hideLoading && "none" }}
      >
        {/* PORTAL */}
        <div
          className="loadingPortal"
          style={{
            backgroundSize:
              scroll <= cutOff
                ? `calc(100vw + ${scroll / 6}rem) calc(100vh + ${
                    scroll / 6
                  }rem)`
                : "350vw 350vh",
          }}
        />
        {/* OBELISK */}
        <img
          className="loadingObelisk"
          alt="OtherGuild Obelisk"
          src={ObeliskImage}
        />
        {/* TEXT */}
        <p
          className="loadingText"
          style={{ opacity: `calc(1 - ${scroll / 100})` }}
        >
          Begin your voyage
        </p>
        {/* TITLE */}
        <div className="loadingTitleContainer">
          <p className="loadingTitle">Welcome to</p>
          <img
            className="loadingTitleImage"
            alt="OtherGuild"
            src={OtherGuildTitle}
          />
        </div>
        {/* TUCK */}
      </div>
      <span
        className="loadingTuck"
        style={{ display: hideLoading && "none" }}
      />
    </Fragment>
  );
};
// EXPORT
export default Loading;
