// IMPORTS
import React, { useRef, useEffect } from "react";
import { team } from "../helpers/variables";
// COMPONENT
const Team = ({ props }) => {
  // STATE
  const { refs } = props;
  const membersRef = useRef(null);
  const scrollRightRef = useRef(true);
  const animationRef = useRef(null);
  const animation = useRef(() => {
    const scrollLeft = membersRef.current.scrollLeft;
    const maxWidth =
      membersRef.current.scrollWidth - membersRef.current.offsetWidth;
    scrollRightRef.current
      ? (membersRef.current.scrollLeft += 1)
      : (membersRef.current.scrollLeft -= 1);
    scrollRightRef.current
      ? scrollLeft >= maxWidth && (scrollRightRef.current = false)
      : scrollLeft <= 0 && (scrollRightRef.current = true);
    animationRef.current = requestAnimationFrame(animation.current);
  });
  // LIFE CYCLE
  useEffect(() => {
    animationRef.current = requestAnimationFrame(animation.current);
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, []);
  // RENDER
  return (
    <div ref={refs.teamRef} className="teamContainer">
      {/* CONTENT */}
      <div className="teamContentContainer">
        {/* TITLE */}
        <p className="teamTitle">The High Council</p>
        {/* TEXT LARGE */}
        <p className="teamTextLarge">Meet Our Team</p>
        {/* TEXT SMALL */}
        <p className="teamTextSmall">
          Our project team is a diverse group of individuals from around the
          globe dedicated to leveraging decades of real world experience in the
          Otherside. Ranging from creative directors, serial entrepreneurs,
          blockchain engineers, 3D artists, and more, there is no limit to what
          this team can accomplish.
        </p>
        {/* MEMBERS */}
        <div
          ref={membersRef}
          className="teamMembersContainer"
          onMouseEnter={() => cancelAnimationFrame(animationRef.current)}
          onMouseLeave={() => {
            animationRef.current = requestAnimationFrame(animation.current);
          }}
        >
          {team.map((member, index) => {
            const { name, description, image, handle, link } = member;
            const largeImage =
              name === "Falconz" ||
              name === "AnythingMachine" ||
              name === "Icon Seed";
            return (
              <div key={index} className="teamMember">
                <p className="teamMemberName">{name}</p>
                <p className="teamMemberDescription">{description}</p>
                <div
                  className={
                    largeImage ? "teamMemberImageLarge" : "teamMemberImage"
                  }
                  style={{ background: `url(${image})` }}
                />
                <a
                  className="teamMemberLink"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {handle}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
// EXPORT
export default Team;
