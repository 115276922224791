// IMPORTS
import React, { useRef } from "react";
import { FaTimes, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { orbs } from "../helpers/variables";
import ObeliskImage from "../assets/images/obelisk/obelisk.png";
import WhiteOrb from "../assets/images/orbs/white.svg";
import BlueOrb from "../assets/images/orbs/blue.svg";
// COMPONENT
const Obelisk = ({ props }) => {
  // STATE
  const { state, setState, styles, refs, close } = props;
  const { selectedOrb, isMobile } = state;
  const { appRef, theCharterRef } = refs;
  const carouselRef = useRef(null);
  const showCarousel = selectedOrb !== null;
  const showOrbs =
    appRef.current?.scrollTop >= theCharterRef.current?.offsetTop - 500 &&
    appRef.current?.scrollTop <=
      theCharterRef.current?.offsetTop +
        theCharterRef.current?.offsetHeight -
        300;
  // MOVE CAROUSEL
  const moveCarousel = (direction, orbID) => {
    let newOrbID = orbID || direction === "left" ? 0 : orbs.length - 1;
    if (direction === "left") {
      if (selectedOrb > 0) {
        newOrbID = selectedOrb - 1;
        isMobile
          ? (carouselRef.current.scrollLeft =
              carouselRef.current.offsetWidth * newOrbID)
          : (carouselRef.current.scrollLeft = 0);
      }
    } else if (direction === "right") {
      if (selectedOrb < orbs.length - 1) {
        newOrbID = selectedOrb + 1;
        isMobile
          ? (carouselRef.current.scrollLeft =
              carouselRef.current.offsetWidth * newOrbID)
          : (carouselRef.current.scrollLeft = carouselRef.current.offsetWidth);
      }
    } else {
      newOrbID = orbID;
      carouselRef.current.scrollLeft =
        carouselRef.current.offsetWidth * newOrbID;
    }
    setState({ ...state, selectedOrb: newOrbID });
  };
  // RENDER
  return (
    <div
      className="obeliskBackground"
      style={{ background: selectedOrb !== null && styles.blackFadeBright }}
    >
      {/* CONTAINER */}
      <div className="obeliskContainer">
        {/* CLOSE BUTTON */}
        <button
          className="obeliskCloseButton"
          style={{ display: !isMobile && "none" }}
          onClick={() => close()}
        >
          <FaTimes className="obeliskCloseButtonIcon" />
        </button>
        {/* TEXT */}
        <p className="obeliskText" style={{ display: !isMobile && "none" }}>
          Click an Orb on the Acropolis to view our Charter
        </p>
        {/* IMAGE */}
        <img className="obeliskImage" alt="Obelisk" src={ObeliskImage} />
        {/* ORBS */}
        {(showOrbs || isMobile) &&
          orbs.map((orb, index) => {
            const { x, y } = orb;
            const isSelected = selectedOrb === index;
            return (
              <button
                key={index}
                className="obeliskOrbButton"
                style={{
                  top: !isMobile && y.desktop,
                  bottom: isMobile && y.mobile,
                  left: isMobile ? x.mobile : x.desktop,
                }}
                onClick={() => {
                  isMobile
                    ? moveCarousel(null, index)
                    : index <= 3
                    ? moveCarousel("left", index)
                    : moveCarousel("right", index);
                }}
              >
                <img
                  className="obeliskOrbImage"
                  alt={`${isSelected ? "White" : "Blue"} Orb`}
                  src={isSelected ? WhiteOrb : BlueOrb}
                />
              </button>
            );
          })}
      </div>
      {/* CAROUSEL */}
      <div
        ref={carouselRef}
        className="obeliskCarouselContainer"
        style={{
          opacity: showCarousel ? "1" : "0",
          pointerEvents: showCarousel ? "all" : "none",
        }}
      >
        <div className="obeliskCarouselControlsContainer">
          {[
            {
              className: "obeliskCarouselLeftArrowButton",
              method: () => moveCarousel("left"),
              icon: <FaArrowLeft />,
            },
            {
              className: "obeliskCarouselRightArrowButton",
              method: () => moveCarousel("right"),
              icon: <FaArrowRight />,
            },

            {
              className: "obeliskCarouselCloseButton",
              method: () => setState({ ...state, selectedOrb: null }),
              icon: <FaTimes />,
            },
          ].map((button, index) => {
            const { className, method, icon } = button;
            return (
              <button key={index} className={className} onClick={method}>
                {icon}
              </button>
            );
          })}
        </div>
        {orbs.map((orb, index) => {
          const { key, value, image } = orb;
          const enlargeImage = index === 1 || index === 2;
          const moveImageUp = index === 6;
          return (
            <div
              key={index}
              className="obeliskCarouselItem"
              onClick={() => {
                !isMobile && setState({ ...state, selectedOrb: null });
              }}
              onMouseEnter={() => setState({ ...state, selectedOrb: index })}
            >
              <img
                className={
                  enlargeImage
                    ? "obeliskCarouselItemImageLarge"
                    : moveImageUp
                    ? "obeliskCarouselImageMoveUp"
                    : "obeliskCarouselItemImage"
                }
                style={{ top: index === 1 && isMobile && "8rem" }}
                alt={key || "Obelisk"}
                src={image}
              />
              <p className="obeliskCarouselItemTitle">{key}</p>
              <p className="obeliskCarouselItemText">{value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
// EXPORT
export default Obelisk;
