// IMPORTS
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoIosGlobe } from "react-icons/io";
import { toPixels } from "../helpers/hooks";
import { links } from "../helpers/variables";
// COMPONENT
const Header = ({ props }) => {
  // STATE
  const { state, styles, refs } = props;
  const { showObelisk, selectedOrb, isMobile, isMobileLandscape, isTablet } =
    state;
  const [showMenu, setShowMenu] = useState(false);
  const allowClick = !showObelisk && !selectedOrb;
  const headerHeight = toPixels(styles.headerHeight);
  const hideMenu = !isMobile && !isMobileLandscape && !isTablet;
  const pages = {
    "Our Story": refs.ourStoryRef,
    "The Charter": refs.theCharterRef,
    Roadmap: refs.roadmapRef,
    Team: refs.teamRef,
    Faq: refs.faqRef,
    "Members Only": refs.membersOnlyRef,
  };
  // GENERATE PAGES
  const generatePages = () => {
    return (
      <div className="headerPagesContainer">
        {Object.keys(pages).map((page, index) => {
          const isMembersOnly = page === "Members Only";
          return (
            <button
              key={index}
              className={
                isMembersOnly ? "headerMembersOnlyButton" : "headerPageButton"
              }
              onClick={() => {
                if (allowClick) {
                  if (isMobileLandscape && page === "Members Only") {
                    refs.appRef.current.scrollTop =
                      refs.appRef.current.scrollHeight;
                  } else {
                    refs.appRef.current.scrollTop =
                      pages[page].current.offsetTop - headerHeight;
                  }
                  if (isMobile || isMobileLandscape || isTablet) {
                    setShowMenu(false);
                  }
                }
              }}
            >
              {page}
            </button>
          );
        })}
        {/* MAP BUTTON */}
        <a
          className="headerMapButton"
          href={links.map}
          target="_blank"
          rel="noreferrer"
        >
          <IoIosGlobe />
        </a>
      </div>
    );
  };
  // RENDER
  return (
    <div className="headerContainer">
      {/* TITLE */}
      <button
        className="headerTitleButton"
        onClick={() => {
          refs.appRef.current.scrollTop =
            refs.landingRef.current.offsetTop - headerHeight;
        }}
      />
      {/* PAGES */}
      {hideMenu && generatePages()}
      {/* HAMBURGER BUTTON */}
      <button
        className="headerHamburgerButton"
        style={{ display: hideMenu && "none" }}
        onClick={() => allowClick && setShowMenu(!showMenu)}
      >
        {showMenu ? <FaTimes /> : <FaBars />}
      </button>
      {/* MENU */}
      <div
        className="headerMenuContainer"
        style={{ display: !showMenu && "none" }}
      >
        {generatePages()}
      </div>
    </div>
  );
};
// EXPORT
export default Header;
