// IMPORTS
import React from "react";
import { links } from "../helpers/variables";
// COMPONENT
const Landing = ({ props }) => {
  // STATE
  const { refs } = props;
  // RENDER
  return (
    <div ref={refs.landingRef} className="landingContainer">
      {/* CONTENT */}
      <div className="landingContentContainer">
        {/* TEXT LARGE */}
        <p className="landingTextLarge">
          Otherguild is a groundbreaking <br /> first-of-its-kind Otherside
          Guild
        </p>
        {/* TEXT SMALL */}
        <p className="landingTextSmall">
          The OtherGuild is a community of Creatives, Developers, Gamers,
          Investors, Adventurers, and Innovators. Join now to gain acces to our
          exclusive members-only benefits, including Otherside tools, alpha, and
          in-game assets.
        </p>
        {/* BUTTON */}
        <a
          className="landingButton"
          href={links.discord}
          target="_blank"
          rel="noreferrer"
        >
          Apply to Join
        </a>
      </div>
    </div>
  );
};
// EXPORT
export default Landing;
