// IMPORTS
import React from "react";
import DeedsImage from "../assets/images/icons/deeds.png";
import KodasImage from "../assets/images/icons/kodas.png";
import ArtifactsImage from "../assets/images/icons/artifacts.png";
import MembersImage from "../assets/images/icons/members.png";
import { links } from "../helpers/variables";
// COMPONENT
const OurStory = ({ props }) => {
  // STATE
  const { state, refs } = props;
  const { showObelisk, selectedOrb } = state;
  const { deeds, kodas, artifacrts, members } = state.data;
  const isStatic = showObelisk || selectedOrb;
  const data = [
    { key: "Deeds", value: deeds, image: DeedsImage },
    { key: "Kodas", value: kodas, image: KodasImage },
    { key: "Artifacts", value: artifacrts, image: ArtifactsImage },
    { key: "Members", value: members, image: MembersImage },
  ];
  // RENDER
  return (
    <div ref={refs.ourStoryRef} className="ourStoryContainer">
      {/* CONTENT */}
      <div className="ourStoryContentContainer">
        {/* TITLE */}
        <p className="ourStoryTitle">OtherGuild Origin</p>
        {/* TEXT LARGE */}
        <p
          className="ourStoryTextLarge"
          style={{ position: isStatic && "static" }}
        >
          With a silent boom <br /> the Otherside formed
        </p>
        {/* TEXT SMALL */}
        <p
          className="ourStoryTextSmall"
          style={{ position: isStatic && "static" }}
        >
          A small band of voyagers who saw the potential of this new world
          gathered and formed the OtherGuild and collectively built the
          Acropolis.
          <br /> <br />
          Now, the obelisk has appeared and a powerful set of heirlooms is being
          forged that will grant only the most intrepid of voyagers passage into
          the Acropolis.
        </p>
        {/* TEXT MEDIUM */}
        <p
          className="ourStoryTextMedium"
          style={{ position: isStatic && "static" }}
        >
          Inside the Acropolis
        </p>
        {/* DATA */}
        <div
          className="ourStoryDataContainer"
          style={{ position: isStatic && "static" }}
        >
          {data.map((item, index) => {
            const { key, value, image } = item;
            return (
              <div key={index} className="ourStoryDataItemContainer">
                <img className="ourStoryDataItemImage" alt={key} src={image} />
                <p className="ourStoryDataItemValue">{value}</p>
                <p className="ourStoryDataItemKey">{key}</p>
              </div>
            );
          })}
        </div>
        {/* BUTTON */}
        <a
          className="ourStoryButton"
          style={{ position: isStatic && "static" }}
          href={links.map}
          target="_blank"
          rel="noreferrer"
        >
          View our map
        </a>
      </div>
    </div>
  );
};
// EXPORT
export default OurStory;
