// IMPORTS
import React from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { questions, deeds } from "../helpers/variables";
// COMPONENT
const FAQ = ({ props }) => {
  // STATE
  const { state, setState, refs } = props;
  const { selectedFAQ } = state;
  // RENDER
  return (
    <div ref={refs.faqRef} className="faqContainer">
      {/* CONTENT */}
      <div className="faqContentContainer">
        {/* TITLE */}
        <p className="faqTitle">Faq</p>
        {/* TEXT LARGE */}
        <p className="faqTextLarge">Frequently Asked Questions</p>
        {/* QUESTIONS */}
        <div className="faqQuestionsContainer">
          {questions.map((faq, index) => {
            const { question, answer } = faq;
            const isSelected =
              JSON.stringify(selectedFAQ) === JSON.stringify(faq);
            return (
              <div
                key={index}
                className="faqQuestion"
                style={{ overflow: !isSelected && "hidden" }}
                onClick={() => {
                  setState({ ...state, selectedFAQ: isSelected ? null : faq });
                }}
              >
                <p className="faqQuestionText">{question}</p>
                {isSelected ? (
                  <FaAngleUp className="faqQuestionIcon" />
                ) : (
                  <FaAngleDown className="faqQuestionIcon" />
                )}
                {isSelected && (
                  <p className="faqQuestionAnswerText">{answer}</p>
                )}
              </div>
            );
          })}
        </div>
        {/* DEEDS */}
        <div className="faqDeedsContainer">
          {deeds.map((deed, index) => {
            return (
              <img
                key={index}
                className="faqDeedImage"
                alt="OtherSide Deed"
                src={deed}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
// EXPORT
export default FAQ;
